import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "@/http/axiosInterceptor";
import { ResponseCookies } from "next/dist/compiled/@edge-runtime/cookies";

const API_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const getLTDPlanDetails = createAsyncThunk(
  "settings/getLTDPlanDetails",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/ltd-plans`);
      dispatch(setLtdPlansData(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getMRRPlanDetails = createAsyncThunk(
  "settings/getMRRPlanDetails",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get("/api/mrr-plans");
      dispatch(setMRRPlansData(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAddOnPlanDetails = createAsyncThunk(
  "settings/getAddOnPlanDetails",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get("/api/addon-plans");
      dispatch(setAddOnPlansData(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const buyAddOnPlan = createAsyncThunk(
  "settings/buyAddOnPlan",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post("/api/buy-addon", {
        order: data,
      });
      dispatch(getBillingDetails());
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const cancelAddOnPlan = createAsyncThunk(
  "settings/cancelAddOnPlan",
  async (plan_id, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/cancel-addon/${plan_id}`);
      dispatch(getBillingDetails());
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCurrentUsage = createAsyncThunk(
  "settings/getCurrentUsage",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/current-usage`);

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getBillingDetails = createAsyncThunk(
  "settings/getBillingDetails",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/billing`);
      // dispatch(setBilling(response.data.data));

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const upgradeUserPlan = createAsyncThunk(
  "settings/upgradeUserPlan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/create-ltd", {
        order: data,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createSubscription = createAsyncThunk(
  "settings/createSubscription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/create-subscription", {
        order: data,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const startSubscriptionForOldUser = createAsyncThunk(
  "settings/startSubscriptionForOldUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post("/api/start-subscription", data);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postProfileImage = createAsyncThunk(
  "settings/postProfileImage",
  async ({ workspace_id, file, id, type }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("workspace_id", workspace_id);
      formData.append("type", type);
      const response = await Api.post(
        `${API_URL}/api/media/upload/user/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteProfileImage = createAsyncThunk(
  "settings/deleteProfileImage",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`${API_URL}/api/media/${id}`);
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "settings/updateSubscription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/update-subscription", {
        plan: data,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "settings/cancelSubscription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post("/api/cancel-subscription", data);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const reactivateAccount = createAsyncThunk(
  "settings/reactivateAccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post("/api/reactivate-subscription", data);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getStripeCustomer = createAsyncThunk(
  "settings/getStripeCustomer",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/api/stripe-customer");
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const downgradeToFreePlan = createAsyncThunk(
  "settings/downgradeToFreePlan",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/assign-free-plan");
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePaymentMethodInfo = createAsyncThunk(
  "settings/updatePaymentMethodInfo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/current-subscription/${data}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSubscriptionInvoices = createAsyncThunk(
  "settings/getSubscriptionInvoices",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/invoices/${data}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const applyCodes = createAsyncThunk(
  "settings/applyCodes",
  async (codes, { rejectWithValue }) => {
    try {
      const response = await Api.post(`${API_URL}/api/apply-coupon-code`, {
        plan: codes,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//
export const getCustomDomain = createAsyncThunk(
  "settings/getCustomDomain",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/custom-domain?settings=true`
      );

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCustomDomainForPortal = createAsyncThunk(
  "settings/getCustomDomainForPortal",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.get("/api/clientportal/custom-domain");

      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSingleCustomDomain = createAsyncThunk(
  "settings/getSingleCustomDomain",
  async (customDomainId, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/custom-domain/${customDomainId}`
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCustomDomainSettings = createAsyncThunk(
  "settings/getCustomDomainSettings",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.get(
        `${API_URL}/api/custom-domain?settings=true`
      );

      return response.data.data;
    } catch (error) {
      console.log("In response", error);
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const saveNewCustomDomain = createAsyncThunk(
  "settings/saveNewCustomDomain",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.post("/api/custom-domain", {
        custom_domain_data: data,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postPageNotFoundUrl = createAsyncThunk(
  "settings/postPageNotFoundUrl",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post("/api/custom-domain/404-page", {
        custom_domain_data: data,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const assignCustomDomainToClients = createAsyncThunk(
  "settings/assignCustomDomainToClients",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post("/api/custom-domain/assign", {
        custom_domain_data: data,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const uploadCustomDomainFavicon = createAsyncThunk(
  "/settings/uploadCustomDomainFavicon",
  async (data, { rejectWithValue }) => {
    try {
      const payload = new FormData();
      payload.append("workspace_id", data?.workspaceId);
      payload.append("type", "logo");
      payload.append("file", data.file);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${API_URL}/api/media/upload/customdomain/${data?.customDomainId}`,
        data: payload,
      };

      const response = await Api.request(config);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteCustomDomainFavicon = createAsyncThunk(
  "/settings/deleteCustomDomainFavicon",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.delete(`${API_URL}/api/media/${data.mediaId}`);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOGValues = createAsyncThunk(
  "settings/getOGValues",
  async ({ moduleType, id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/open-graph-tag/${moduleType}/${id}`
      );
      dispatch(setOgState(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postOGValues = createAsyncThunk(
  "settings/postOGValues",
  async ({ moduleType, data, id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/api/open-graph-tag/${moduleType}/${id}`,
        { open_graph_tags_data: data }
      );
      await dispatch(getOGValues({ moduleType, id }));
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteOGImage = createAsyncThunk(
  "settings/deleteOGImage",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/api/media/${id}`);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTotalProjects = createAsyncThunk(
  "setings/getTotalProjects",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/total-projects`);
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPaymentMethods = createAsyncThunk(
  "settings/getPaymentMethods",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/payment-integrations`);
      return response.data;
    } catch (error) {
      console.log("error", error);
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addPaymentIntegration = createAsyncThunk(
  "settings/addPaymentMode",
  async (paymentData, { rejectWithValue }) => {
    try {
      const response = await Api.post(`/api/payment-integration`, paymentData);

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editPaymentMode = createAsyncThunk(
  "settings/editPaymentMode",
  async (paymentMode, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/payment-integration`, {
        payment_integration_data: paymentMode,
      });
      dispatch(updatePayment(response.data));
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getLinkAnalytics = createAsyncThunk(
  "settings/getLinkAnalytics",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `api/analytics/${data.entityType}/${data.entityId}/?page=${data.page}&startDate=${data.startDate}&endDate=${data.endDate}`
      );
      dispatch(`${data.entityType}/SET_ANALYTICS_INFO`, response.data.data, {
        root: true,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteCustomDomain = createAsyncThunk(
  "settings/deleteCustomDomain",
  async (customDomainId, { dispatch, rejectWithValue }) => {
    try {
      const response = await Api.delete(`/api/custom-domain/${customDomainId}`);
      dispatch(getCustomDomainSettings());
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createCheckoutSession = createAsyncThunk(
  "settings/createCheckoutSession",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/create-session`, { data });
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postUserInfo = createAsyncThunk(
  "settings/postUserInfo",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/auth/update-user`,
        userData
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postChangePassword = createAsyncThunk(
  "settings/postChangePassword",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await Api.post(
        `${API_URL}/api/auth/change-password`,
        userData
      );
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "settings/getUserDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/auth/user`);
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCountryCode = createAsyncThunk(
  "settings/getCountryCode",
  async (_, { rejectWithValue }) => {
    try {
      let currentCountry = "US";
      const response = await axios.get("https://ip2c.org/s");
      const result = response.data;
      const arrayOfResponse = result.split(";");
      switch (arrayOfResponse[0]) {
        case "0":
          currentCountry = "Something wrong";
          break;
        case "1":
          currentCountry = arrayOfResponse[1];
          break;
        case "2":
          currentCountry = "Not found in database";
          break;
      }
      return currentCountry;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllWorkspaceClients = createAsyncThunk(
  "/settings/getAllWorkspaceClients",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.get(`${API_URL}/api/contacts/all`);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const assignFreePlan = createAsyncThunk(
  "/assign-free-plan-thunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api.post(`${API_URL}/api/assign-free-plan`);
      return response.data.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

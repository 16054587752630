import axios from "axios";
import Cookies from "js-cookie";
import { DateTime } from "luxon";

const Api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  timeout: 120000,
});

Api.interceptors.request.use(
  (config) => {
    // const localToken = Cookies.get("auth.local.token");

    const workspace = Cookies.get("Workspace");

    const strategy = Cookies.get("auth.strategy");
    if (strategy === "portal") {
      const localToken = Cookies.get("auth.portal.token");
      if (localToken) {
        config.headers.Authorization = "Bearer " + localToken;
      }
    } else {
      const localToken = Cookies.get("auth.local.token");
      if (localToken) {
        config.headers.Authorization = "Bearer " + localToken;
      }
    }

    const Timezone = workspace ? workspace.timezone : "";

    if (workspace) {
      config.headers["Workspace"] = workspace;
    }

    const setTimeZone = Timezone || DateTime.local().zoneName;

    config.headers["Timezone"] = setTimeZone;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let workspaceId;

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      const strategyType = Cookies.get("auth.strategy");
      if (strategyType === "portal") {
        Cookies.remove("auth.portal.token");
        Cookies.remove("ContactUUID");
        const workspace = JSON.parse(localStorage.getItem("workspace"));

        if (!workspaceId) {
          workspaceId = workspace?.uuid;
        }

        Cookies.remove("Workspace");
        window.location.href = `/clientportal/sign-in?workspace_id=${workspaceId}`;

        localStorage.clear();
      } else {
        Cookies.remove("auth.local.token");
        Cookies.remove("Workspace");
        Cookies.remove("workspace");
        // Cookies.remove("auth.strategy");
        Cookies.remove("ContactUUID");
        localStorage.clear();
        window.location.href = "/auth/sign-in";
      }
    }
    return Promise.reject(error);
  }
);

export default Api;
